<template>
  <div>

    <b-modal
      id="mdlProductoBeneficio"
      v-model="showBeneficioProductoModal"
      centered
      :title="isTitle"
      cancel-title="Cancelar"
      ok-title="Aceptar"
      cancel-variant="secondary"
      ok-variant="primary"
      size="lg"
      no-close-on-backdrop
      @ok="okModal"
      @cancel="closeModal"
      @close="closeModal"
    >
      <validation-observer ref="beneficioProductoObserver">
        <b-form>

          <b-overlay
            :show="isWorking"
            :rounded="overlayConfig.rounded"
            :variant="overlayConfig.variant"
            :opacity="overlayConfig.opacity"
            :blur="overlayConfig.blur"
          >
            <template #overlay>
              <div v-if="isWorking">
                <div style="text-align: center">
                  <span class="spinner-border " />
                </div>
                <div style="text-align: center">
                  <small>{{ overlayConfig.message }}</small>
                </div>
              </div>
            </template>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="selProducto"
                  label="Producto"
                >
                  <validation-provider
                    name="selProductoValidator"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectBeneficioProducto"
                        v-model="selectedProducto"
                        :options="productosOption"
                        :state="errors.length > 0 ? false:null"
                        value-field="id"
                        text-field="nombre"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="chkAfiliado"
                  label="Beneficiario"
                >
                  <validation-provider
                    name="chkBeneficiariosValidator"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-checkbox-group
                        v-model="selectedSolicitantes"
                        name="check-button"
                        :options="solicitantesOptions"
                        :state="errors.length > 0 ? false:null"
                        value-field="id"
                        text-field="nombre"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Seleccione al menos un beneficiario.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

          </b-overlay>

        </b-form>

      </validation-observer>
      <template #modal-footer>
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          size="sm"
          squared
          variant="outline-secondary"
          @click="closeModal"
        >
          Cancelar
        </b-button>
        <b-button
          size="sm"
          squared
          variant="outline-secondary"
          @click="okModal"
        >
          Aceptar
        </b-button>

      </template>
    </b-modal>

  </div>
</template>
<script>
import { productoResource } from '@/services/productoResource'

export default {
  name: 'BeneficioProducto',
  components: {
  },
  props: {
    beneficioProducto: {
      type: Object,
      required: false,
      default: null,
    },
    listaProductosAsignados: {
      type: Array,
      required: true,
    },
    categoriaSolicitante: {
      type: Array,
      required: true,
    },
    showBeneficioProductoModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isWorking: false,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      isTitle: 'Producto beneficio',
      isSaveOverlay: false,
      productosOption: [{ id: null, nombre: 'Seleccione ...' }],
      productosAsignados: [],
      selectedProducto: null,
      selectedSolicitantes: [],
      solicitantesOptions: [],
      productoBeneficioDto: {},
    }
  },
  computed: {
  },
  created() {
    this.productosAsignados = [...this.listaProductosAsignados]
    this.solicitantesOptions = [...this.categoriaSolicitante]

    if (this.openMode === 'edit') {
      this.beneficioProdDto = JSON.parse(JSON.stringify(this.beneficioProducto))
      this.selectedProducto = this.beneficioProducto.producto.id
      this.beneficioProducto.categoriaSolicitante.forEach(catSolic => {
        this.selectedSolicitantes.push(catSolic.id)
      })
    }

    this.findProductosNotInThisList()
  },
  methods: {
    closeModal(bvModalEvt) {
      this.$emit('contentBeneficioProducto', false)
      bvModalEvt.preventDefault()
    },
    okModal(bvModalEvt) {
      this.validationBeneficioProductoModal()
      bvModalEvt.preventDefault()
    },
    emiteNew() {
      const prodArrayUnique = this.productosOption.filter(producto => producto.id === this.selectedProducto)
      const prodSelected = prodArrayUnique[0]
      const newBeneficioProducto = {
        esActivo: true,
        id: this.generarRandomId(),
        producto: {
          activo: true,
          id: prodSelected.id,
          nombre: prodSelected.nombre,
        },
        categoriaSolicitante: [],
      }
      this.selectedSolicitantes.forEach(solicId => {
        const solicitante = this.solicitantesOptions.filter(solicOpt => solicOpt.id === solicId)
        newBeneficioProducto.categoriaSolicitante.push(solicitante[0])
      })
      this.$emit('newBeneficioProducto', { beneficioProd: newBeneficioProducto })
    },
    emiteUpdate() {
      this.beneficioProdDto.categoriaSolicitante = []
      const prodArrayUnique = this.productosOption.filter(producto => producto.id === this.selectedProducto)
      const prodSelected = prodArrayUnique[0]
      this.beneficioProdDto.producto.activo = prodSelected.activo
      this.beneficioProdDto.producto.id = prodSelected.id
      this.beneficioProdDto.producto.nombre = prodSelected.nombre
      this.selectedSolicitantes.forEach(solicId => {
        const solicitante = this.solicitantesOptions.filter(solicOpt => solicOpt.id === solicId)
        this.beneficioProdDto.categoriaSolicitante.push(solicitante[0])
      })
      this.$emit('setBeneficioProducto', { beneficioProd: this.beneficioProdDto })
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    getSimpleIdList() {
      const listProductosId = []

      this.productosAsignados.forEach(productoAsignado => {
        if (this.openMode === 'edit') {
          if (this.beneficioProducto.producto.id !== productoAsignado.producto.id) {
            listProductosId.push(productoAsignado.producto.id)
          }
        } else {
          listProductosId.push(productoAsignado.producto.id)
        }
      })

      return listProductosId
    },
    findProductosNotInThisList() {
      this.isWorking = true
      this.overlayConfig.message = 'Buscando lista de productos'
      productoResource().findAllNotInThisList(this.getSimpleIdList()).then(resultado => {
        if (this.openMode === 'edit') {
          this.productosOption = resultado
        } else {
          this.productosOption = this.productosOption.concat(resultado)
        }
      }).catch(error => {
        error.log(error)
      })
        .finally(() => {
          this.isWorking = false
        })
    },
    validationBeneficioProductoModal() {
      this.$refs.beneficioProductoObserver.validate().then(success => {
        if (success) {
          if (this.openMode === 'edit') {
            this.emiteUpdate()
          }
          if (this.openMode === 'new') {
            this.emiteNew()
          }
        }
      })
    },
  },
}
</script>
