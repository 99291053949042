<template>
  <div>
    <validation-observer ref="configBeneficioGenerico">
      <b-form>

        <b-card>
          <b-overlay
            :show="isWorking"
            :rounded="overlayConfig.rounded"
            :variant="overlayConfig.variant"
            :opacity="overlayConfig.opacity"
            :blur="overlayConfig.blur"
          >
            <template #overlay>
              <div v-if="isWorking">
                <div style="text-align: center">
                  <span class="spinner-border " />
                </div>
                <div style="text-align: center">
                  <small>{{ overlayConfig.message }}</small>
                </div>
              </div>
            </template>
            <b-row>
              <b-col md="2">
                <p>Nombre beneficio</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="'Identificación del beneficio tal como se mostrará en el portal. Deberá estar activo para que aparezca en el Panel de Beneficios'"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
              <b-col md="1">
                <b-form-checkbox
                  v-model="beneficioForm.esActivo"
                  v-b-tooltip.hover="'Activar / Desactivar el beneficio'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :disabled="!isEditable"
                >
                  <small class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </small>
                </b-form-checkbox>
              </b-col>
              <b-col md="6">

                <small v-if="beneficioForm.esActivo">
                  Beneficio activado
                </small>
                <small
                  v-if="!beneficioForm.esActivo"
                  style="color:orange"
                >
                  Beneficio desactivado
                </small>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <validation-provider
                  name="nombreBeneficio"
                  rules="required|max:50"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <b-form-input
                      id="txtNombreBeneficio"
                      v-model="beneficioForm.nombre"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                      :disabled="!isEditable"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Campo obligatorio
                    </small>
                    <small
                      v-if="failedRules.max"
                      class="text-danger"
                    >Máximo 50 caracteres.
                    </small>
                  </div>
                </validation-provider>
              </b-col>
            </b-row>

            <br>

            <b-row>
              <b-col md="2">
                <p>Tipo beneficio</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="'Categoría del beneficio'"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <validation-provider
                  name="tipoBeneficios"
                  rules="required"
                >
                  <div slot-scope="{errors, failedRules}">
                    <b-form-select
                      id="selectBeneficioTipo"
                      v-model="beneficioForm.beneficioTipo"
                      :options="beneficioTipoOptions"
                      :state="errors.length > 0 ? false:null"
                      :disabled="!isEditable"
                      @change="changedBeneficioTipo"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Campo obligatorio</small>
                  </div>
                </validation-provider>
              </b-col>
            </b-row>
            <hr v-if="(isTipoConProducto || isTipoQuincho) && !isTipoNonSelected">

            <b-row v-if="(isTipoConProducto || isTipoQuincho) && !isTipoNonSelected">
              <b-col md="2">
                <p>Controlar vigencia</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="'Indica si se podrá solicitar el beneficio solo si está activo en un plazo de tiempo determinado. Debe ingresar el rango de fechas. De lo contrario se podrá solicitar un beneficio activo en cualquier momento.'"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
              <b-col md="1">
                <b-form-checkbox
                  v-model="beneficioForm.controlarVigencia"
                  v-b-tooltip.hover="'Activar / Desactivar el control de vigencia'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :disabled="!isEditable"
                  @change="makeNullFechaVigencia"
                >
                  <small class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </small>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-card v-if="beneficioForm.controlarVigencia">
              <b-row>

                <b-col md="4">
                  <b-form-group
                    label-for="fechadesde"
                    label="Fecha desde"
                  >
                    <validation-provider
                      name="FechaDesde"
                      rules="required|min:8"
                    >
                      <div slot-scope="{ errors, failedRules }">
                        <cleave
                          id="date"
                          v-model="beneficioForm.vigenciaDesde"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          :raw="true"
                          :options="options.date"
                          autocomplete="off"
                          placeholder="fecha desde DD/MM/AAAA"
                          :disabled="!isEditable"
                        />
                        <small
                          v-if="failedRules.required"
                          class="text-danger"
                        >Campo obligatorio
                        </small>
                        <small
                          v-if="failedRules.min"
                          class="text-danger"
                        >Campo obligatorio
                        </small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label-for="fechaHasta"
                    label="Fecha hasta"
                  >
                    <validation-provider
                      name="FechaHasta"
                      rules="required|min:8"
                    >
                      <div slot-scope="{ errors, failedRules }">
                        <cleave
                          id="date"
                          v-model="beneficioForm.vigenciaHasta"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          :raw="true"
                          :options="options.date"
                          autocomplete="off"
                          placeholder="fecha desde DD/MM/AAAA"
                          :disabled="!isEditable"
                        />
                        <small
                          v-if="failedRules.required"
                          class="text-danger"
                        >Campo obligatorio
                        </small>
                        <small
                          v-if="failedRules.min"
                          class="text-danger"
                        >Campo obligatorio
                        </small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-card>
            <hr v-if="!isTipoNonSelected && !isTipoQuincho">

            <b-row v-if="!isTipoNonSelected && !isTipoQuincho">
              <b-col md="2">
                <p>Controlar antigüedad</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="infoAntiguedad"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
              <b-col md="1">
                <b-form-checkbox
                  v-model="beneficioForm.controlarAntiguedad"
                  v-b-tooltip.hover="'Activar / Desactivar el control de antigüedad'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :disabled="!isEditable"
                  @change="makeNullFechaEntrega"
                >
                  <small class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </small>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-card v-if="beneficioForm.controlarAntiguedad && !isTipoQuincho">
              <b-row>
                <b-col
                  v-if="!isTipoConProductoPerpetuo && !isTipoConProductoPaniales"
                  md="4"
                >
                  <b-form-group
                    v-if="!isTipoConProductoPerpetuo && !isTipoConProductoPaniales"
                    label="Fecha de entrega"
                  >
                    <validation-provider
                      name="FechaEntrega"
                      rules="required|min:8"
                    >
                      <div slot-scope="{ errors, failedRules }">
                        <cleave
                          id="date"
                          v-model="beneficioForm.fechaEntrega"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          :raw="true"
                          :options="options.date"
                          autocomplete="off"
                          placeholder="fecha desde DD/MM/AAAA"
                          :disabled="!isEditable"
                        />
                        <small
                          v-if="failedRules.required"
                          class="text-danger"
                        >Campo obligatorio
                        </small>
                        <small
                          v-if="failedRules.min"
                          class="text-danger"
                        >Campo obligatorio
                        </small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Antigüedad mínima (Meses)">
                    <validation-provider
                      name="antiguedadMinima"
                      rules="required"
                    >
                      <div slot-scope="{ errors, failedRules }">
                        <b-form-input
                          id="input-3"
                          v-model="beneficioForm.antiguedadMinima"
                          type="number"
                          placeholder=""
                          :state="errors.length > 0 ? false : null"
                          :disabled="!isEditable"
                          required
                        />
                        <small
                          v-if="failedRules.required"
                          class="text-danger"
                        >Campo obligatorio</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <hr v-if="!isTipoNonSelected && isTipoConProductoPaniales">

            <b-row v-if="!isTipoNonSelected && isTipoConProductoPaniales">
              <b-col md="2">
                <p>Controlar edad</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="'Solo se puede solicitar para hijos que tengan edad menor o igual a la indicada en el campo Edad'"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
              <b-col md="1">
                <b-form-checkbox
                  v-model="beneficioForm.controlarEdad"
                  v-b-tooltip.hover="'Activar / Desactivar el control de edad'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :disabled="!isEditable"
                  @change="makeNullEdadMaxima"
                >
                  <small class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </small>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-card v-if="beneficioForm.controlarEdad && isTipoConProductoPaniales">
              <b-row>
                <b-col
                  v-if="isTipoConProductoPaniales"
                  md="4"
                >
                  <b-form-group label="Edad (meses)">
                    <validation-provider
                      name="edadMaxima"
                      rules="required"
                    >
                      <div slot-scope="{ errors, failedRules }">
                        <b-form-input
                          id="input-3"
                          v-model="beneficioForm.edadMaxima"
                          type="number"
                          placeholder=""
                          :state="errors.length > 0 ? false : null"
                          :disabled="!isEditable"
                          required
                        />
                        <small
                          v-if="failedRules.required"
                          class="text-danger"
                        >Campo obligatorio</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>

            <hr v-if="!isTipoNonSelected && isTipoConProductoPaniales">

            <b-row v-if="!isTipoNonSelected && isTipoConProductoPaniales">
              <b-col md="2">
                <p>Controlar cantidad de entregas</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="'Como máximo se permitirá la cantidad de entregas indicadas en el campo (Cantidad de entregas máxima)'"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
              <b-col md="1">
                <b-form-checkbox
                  v-model="beneficioForm.controlarCantEntregas"
                  v-b-tooltip.hover="'Activar / Desactivar el control de cantidad de entregas'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :disabled="!isEditable"
                  @change="makeNullCantEntregas"
                >
                  <small class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </small>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-card v-if="beneficioForm.controlarCantEntregas && isTipoConProductoPaniales">
              <b-row>
                <b-col
                  v-if="isTipoConProductoPaniales"
                  md="4"
                >
                  <b-form-group label="Cantidad de entregas máxima">
                    <validation-provider
                      name="cantEntregas"
                      rules="required"
                    >
                      <div slot-scope="{ errors, failedRules }">
                        <b-form-input
                          id="input-3"
                          v-model="beneficioForm.cantEntregas"
                          type="number"
                          placeholder=""
                          :state="errors.length > 0 ? false : null"
                          :disabled="!isEditable"
                          required
                        />
                        <small
                          v-if="failedRules.required"
                          class="text-danger"
                        >Campo obligatorio</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <hr v-if="!isTipoNonSelected && !isTipoQuincho">

            <b-row v-if="!isTipoNonSelected && !isTipoQuincho">
              <b-col md="2">
                <p>Solicitar adjuntos</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="'Indica si el afiliado deberá adjuntar archivos al momento de solicitar el beneficio.'"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
              <b-col md="1">
                <b-form-checkbox
                  v-model="beneficioForm.solicitarAdjuntos"
                  v-b-tooltip.hover="'Activar / Desactivar la solicitud de archivos adjuntos'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :disabled="!isEditable"
                >
                  <small class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </small>
                </b-form-checkbox>
              </b-col>
            </b-row>

            <hr v-if="!isTipoNonSelected">

            <b-row v-if="!isTipoNonSelected">
              <b-col md="2">
                <p>Mostrar requisitos</p>
              </b-col>
              <b-col md="1">
                <font-awesome-icon
                  v-b-tooltip.hover="'Indica si se debe mostrar un mensaje al afiliado al momento de solicitar el beneficio. Debe ingresar el texto del mensaje.'"
                  icon="fa-solid fa-circle-info"
                />
              </b-col>
              <b-col md="1">
                <b-form-checkbox
                  v-model="beneficioForm.mostrarRequisitos"
                  v-b-tooltip.hover="'Activar / Desactivar Mostrar requisitos'"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  :disabled="!isEditable"
                  @change="makeNullRequisitos"
                >
                  <small class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </small>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-card v-if="beneficioForm.mostrarRequisitos">
              <b-row>
                <!--maxlength="200"
                                    rows="3"
                    max-rows="4"
                -->
                <b-col md="12">
                  <validation-provider
                    name="antiguedadMinima"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-textarea
                        id="textAreaRequisitos"
                        v-model="beneficioForm.requisitos"
                        placeholder="Requisitos"
                        :state="errors.length > 0 ? false:null"
                        rows="6"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Campo obligatorio</small>
                    </div>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
            <hr v-if="isTipoConProducto || isTipoConProductoPerpetuo || isTipoConProductoPaniales">
            <b-card-text
              v-if="isTipoConProducto || isTipoConProductoPerpetuo || isTipoConProductoPaniales"
              class="mx-2"
            >
              <b-row>
                <b-col md="0" />
                <b-col md="8">
                  <b-button
                    v-if="permisosIns.includes(nameModulo) && (isTipoConProducto || isTipoConProductoPerpetuo || isTipoConProductoPaniales)"
                    id="btnAddBeneficioProducto"
                    v-b-tooltip.hover
                    squared
                    style="float: right;"
                    size="sm"
                    title="Agregar producto"
                    variant="success"
                    :disabled="!isEditable"
                    @click="()=>addBeneficioProducto()"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-table
                    id="tblProductos"
                    ref="refTblProductos"
                    :items="beneficioForm.beneficioProducto"
                    :fields="fieldsBeneficioProducto"
                    show-empty
                    outlined
                    borderless
                    :no-provider-sorting="false"
                    small
                    :no-border-collapse="true"
                    empty-text="No se encontraron resultados para esta búsqueda"
                    :state="!validarArrayEmpty"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center">
                        <b-spinner />
                      </div>
                    </template>
                    <template #cell(producto)="row">
                      <small>
                        {{ row.item.producto.nombre }}
                      </small>
                      <span v-if="!row.item.producto.activo">
                        <font-awesome-icon
                          v-b-tooltip.hover="'Este producto se encuentra inactivo, por lo tanto no estará disponible en solicitudes de beneficios. Si lo desea, puede quitarlo de la lista.' "
                          style="color:orange"
                          icon="fa-solid fa-triangle-exclamation"
                        />
                      </span>
                    </template>
                    <template #cell(categorias)="row">
                      <small>
                        {{ getSimpleList(row.item) }}
                      </small>
                    </template>
                    <template #cell(actions)="row">
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <b-button
                          v-if="permisosUpd.includes(nameModulo) && row.item.producto.activo"
                          v-b-tooltip.hover="'Editar'"
                          size="sm"
                          :disabled="row.item.isProcesando || !isEditable"
                          @click="viewDetalleProductoBeneficio(row.item, row.index, $event.target)"
                        >
                          <!--<feather-icon icon="EyeIcon" />-->
                          <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                        </b-button>
                        <b-button
                          v-if="permisosDel.includes(nameModulo)"
                          v-b-tooltip.hover="'Eliminar'"
                          :disabled="row.item.isProcesando || !isEditable"
                          size="sm"
                          @click="eliminarBeneficioProducto(row.item, row.index, $event.target)"
                        >
                          <font-awesome-icon
                            v-if="!row.item.isProcesando"
                            icon="fa-solid fa-trash"
                          />
                          <span
                            v-if="row.item.isProcesando"
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          />
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
                <b-col md="4" />
              </b-row>
              <span
                v-if="!validarArrayEmpty"
                class="text-danger"
              >Al menos un producto debe ser agregado</span>
            </b-card-text>
          </b-overlay>
        </b-card>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnBackBeneficio"
                v-b-tooltip.hover
                title="Volver a listado beneficios"
                variant="light"
                squared
                @click="()=>backBeneficio()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnSaveBeneficio"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar beneficio"
                type="submit"
                :disabled="isWorking || !isEditable"
                @click.prevent="validationBeneficioForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>
    <div v-if="showBeneficioProductoModal">
      <beneficio-producto
        :beneficio-producto="modalDataBeneficioProducto"
        :lista-productos-asignados="modalDataListaProductosAsignados"
        :show-beneficio-producto-modal="showBeneficioProductoModal"
        :categoria-solicitante="preloadedCategoriaSolicitante"
        :open-mode="openModeModal"
        @contentBeneficioProducto="contentBeneficioProducto"
        @newBeneficioProducto="newBeneficioProducto"
        @setBeneficioProducto="setBeneficioProducto"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { beneficiosResource } from '@/services/beneficiosResource'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import {
  BENE_TIPO_CON_PRODUCTO,
  BENE_TIPO_CON_PRODUCTO_PERPETUO,
  BENE_TIPO_QUINCHO,
  BENE_TIPO_CON_PRODUCTO_PANIALES,
} from '@/utils/codigoTipoBeneConst'
import {
  required, confirmed, email, max, length,
  min,
} from '@validations'
import BeneficioProducto from './BeneficioProducto.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BeneficioProducto,
  },
  props: {
    beneficio: {
      type: Object,
      required: false,
      default: undefined,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
      },
      showBeneficioProductoModal: false,
      selected: true,
      isWorking: false,
      required,
      confirmed,
      length,
      email,
      max,
      min,
      cargando: false,
      cargandoRoles: false,
      nameModulo: 'CONFIGURACION_BENEFICIO',
      preloadedRoles: null,
      sele: null,
      preloadedBeneficioTipo: [],
      beneficioTipoOptions: [{
        value: null,
        text: 'Seleccione ...',
      }],
      preloadedCategoriaSolicitante: null,
      beneficioForm: {
        id: null,
        nombre: '',
        codigo: '',
        beneficioTipo: null,
        esActivo: true,
        descripcion: null,
        controlarVigencia: false,
        vigenciaDesde: null,
        vigenciaHasta: null,
        controlarAntiguedad: false,
        controlarEdad: false,
        edadMaxima: null,
        controlarCantEntregas: false,
        cantEntregas: null,
        fechaEntrega: null,
        antiguedadMinima: 0,
        solicitarAdjuntos: false,
        mostrarRequisitos: false,
        requisitos: null,
        seleccionarProductos: false,
        delegacion: {
          id: null,
          nombre: null,
        },
        beneficioProducto: [
        ],
      },
      modalDataBeneficioProducto: {},
      modalDataListaProductosAsignados: [],
      openModeModal: null,
      fieldsBeneficioProducto: [
        { key: 'producto', label: 'Producto' },
        { key: 'categorias', label: 'Beneficiarios' },
        { key: 'actions', label: 'Acciones', thStyle: { width: '15%' } },
      ],
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
        number: {
          numeral: false,
          blocks: [18],
          numeralPositiveOnly: true,
          numericOnly: true,
          numeralDecimalMark: '',
          delimiter: '',
        },
      },
      BENE_TIPO_CON_PRODUCTO,
      BENE_TIPO_CON_PRODUCTO_PERPETUO,
      BENE_TIPO_QUINCHO,
      BENE_TIPO_CON_PRODUCTO_PANIALES,
    }
  },
  computed: {
    infoAntiguedad() {
      let text
      if (this.isTipoConProducto) {
        text = 'Indica si se debe validar la antigüedad del afiliado para que pueda solicitar el beneficio. Debe ingresar la fecha de entrega estimada y la antigüedad mínima en meses del afiliado.'
      } else if (this.isTipoConProductoPerpetuo) {
        text = 'Indica si se debe validar la antigüedad del afiliado para que pueda solicitar el beneficio. Debe ingresar a antigüedad mínima en meses del afiliado.'
      }
      return text
    },
    validarArrayEmpty() {
      if ((this.isTipoConProducto || this.isTipoConProductoPerpetuo || this.isTipoConProductoPaniales) && this.beneficioForm.beneficioProducto.length === 0) {
        return false
      }
      // De lo contrario, verificamos que el listado tenga al menos un producto.
      return true
    },
    isTipoConProducto() {
      if (this.beneficioForm.beneficioTipo !== null) {
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO) {
          return true
        }
      }
      return false
    },
    isTipoConProductoPerpetuo() {
      if (this.beneficioForm.beneficioTipo !== null) {
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PERPETUO) {
          return true
        }
      }
      return false
    },
    isTipoConProductoPaniales() {
      if (this.beneficioForm.beneficioTipo !== null) {
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) {
          return true
        }
      }
      return false
    },
    isTipoQuincho() {
      if (this.beneficioForm.beneficioTipo !== null) {
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_QUINCHO) {
          return true
        }
      }
      return false
    },
    isTipoNonSelected() {
      if (this.beneficioForm.beneficioTipo === null) {
        return true
      }
      return false
    },
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.beneficioForm = JSON.parse(JSON.stringify(this.beneficio))
    if (this.beneficioForm.beneficioTipo.id === null) {
      this.beneficioForm.beneficioTipo = null
    }
    // const benTipo = { text: 'Seleccionessss ...', value: null }

    this.preloadedRoles = this.$store.getters.getRoles
    this.preloadedBeneficioTipo = this.preloadedBeneficioTipo.concat(this.$store.getters.getBeneficioTipo)
    this.preloadedBeneficioTipo.forEach(tipo => {
      const item = {
        value: tipo,
        text: tipo.nombre,
      }
      this.beneficioTipoOptions.push(item)
    })
    this.preloadedCategoriaSolicitante = this.$store.getters.getCategoriaSolicitante
  },
  methods: {
    changedBeneficioTipo() {
      if (this.beneficioForm.beneficioTipo !== null) {
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO) {
          // tipo producto
          this.makeNullResetCampospaniales()
          // this.makeNullFechaVigencia()
        }
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PERPETUO) {
          // tipo producto perpetuo
          this.makeNullResetCampospaniales()
          this.beneficioForm.controlarVigencia = false
          this.beneficioForm.fechaEntrega = null
          this.makeNullFechaVigencia()
        }
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) {
          // tipo producto pañales
          this.beneficioForm.controlarVigencia = false
          this.makeNullFechaVigencia()
        }
        if (this.beneficioForm.beneficioTipo.codigo === BENE_TIPO_QUINCHO) {
          // tipo quincho
          this.makeNullResetCampospaniales()
          this.beneficioForm.solicitarAdjuntos = false
          this.beneficioForm.beneficioProducto = []
          this.beneficioForm.controlarVigencia = false
          this.beneficioForm.fechaEntrega = null
          this.beneficioForm.controlarAntiguedad = false
          this.beneficioForm.antiguedadMinima = null
          this.makeNullFechaVigencia()
        }
      } else {
        this.resetForm()
      }
    },
    resetForm() {
      this.beneficioForm.beneficioTipo = null
      this.beneficioForm.controlarVigencia = false
      this.beneficioForm.vigenciaDesde = null
      this.beneficioForm.vigenciaHasta = null
      this.beneficioForm.controlarAntiguedad = false
      this.beneficioForm.fechaEntrega = null
      this.beneficioForm.controlarEdad = false
      this.beneficioForm.edadMaxima = null
      this.beneficioForm.controlarCantEntregas = false
      this.beneficioForm.cantEntregas = null
      this.beneficioForm.solicitarAdjuntos = false
      this.beneficioForm.mostrarRequisitos = false
      this.beneficioForm.requisitos = null
      this.beneficioForm.seleccionarProductos = false
      this.beneficioForm.beneficioProducto = []
    },
    makeNullRequisitos() {
      this.beneficioForm.requisitos = null
    },
    makeNullEdadMaxima() {
      this.beneficioForm.edadMaxima = null
    },
    makeNullCantEntregas() {
      this.beneficioForm.cantEntregas = null
    },
    makeNullFechaVigencia() {
      this.beneficioForm.vigenciaDesde = null
      this.beneficioForm.vigenciaHasta = null
    },
    makeNullResetCampospaniales() {
      this.beneficioForm.controlarEdad = false
      this.beneficioForm.controlarCantEntregas = false
      this.beneficioForm.cantEntregas = null
      this.beneficioForm.edadMaxima = null
    },
    makeNullFechaEntrega() {
      // this.beneficioForm.controlarAntiguedad ?
      this.beneficioForm.fechaEntrega = null
      this.beneficioForm.antiguedadMinima = null
    },
    newBeneficioProducto({ beneficioProd }) {
      this.showBeneficioProductoModal = false
      this.beneficioForm.beneficioProducto.push(beneficioProd)
    },
    setBeneficioProducto({ beneficioProd }) {
      this.showBeneficioProductoModal = false
      const pos = this.beneficioForm.beneficioProducto.findIndex(benProd => benProd.id === beneficioProd.id)
      this.beneficioForm.beneficioProducto[pos] = beneficioProd
      this.$refs.refTblProductos.refresh()
    },
    contentBeneficioProducto() {
      this.showBeneficioProductoModal = false
    },
    addBeneficioProducto() {
      this.openModeModal = 'new'
      this.modalDataBeneficioProducto = null
      this.modalDataListaProductosAsignados = this.beneficioForm.beneficioProducto
      this.showBeneficioProductoModal = true
    },
    viewDetalleProductoBeneficio(data) {
      this.openModeModal = 'edit'
      this.modalDataBeneficioProducto = { ...data }
      this.modalDataListaProductosAsignados = this.beneficioForm.beneficioProducto
      this.showBeneficioProductoModal = true
    },
    eliminarBeneficioProducto(beneficioPoducto) {
      const objIndex = this.beneficioForm.beneficioProducto.findIndex((obj => obj.id === beneficioPoducto.id))
      this.beneficioForm.beneficioProducto.splice(objIndex, 1)
    },
    getSimpleList(beneficioProducto) {
      let simpleList = ''
      beneficioProducto.categoriaSolicitante.forEach(catSolc => {
        simpleList += `${catSolc.nombreCustom} `
      })
      return simpleList
    },
    actionBeneficio() {
      this.isWorking = true
      const beneficioDto = JSON.parse(JSON.stringify(this.beneficioForm))
      beneficioDto.fechaEntrega = this.formatDateToJavaT(beneficioDto.fechaEntrega, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      beneficioDto.vigenciaDesde = this.formatDateToJavaT(beneficioDto.vigenciaDesde, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      beneficioDto.vigenciaHasta = this.formatDateToJavaT(beneficioDto.vigenciaHasta, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')

      beneficioDto.beneficioProducto.forEach((benProd, index) => {
        const str = benProd.id
        if (str.toString().includes('generated')) {
          beneficioDto.beneficioProducto[index].id = null
        }
      })
      if (this.beneficioForm.id !== null) {
        this.overlayConfig.message = 'Guardando cambios'
        beneficiosResource().updateBeneficio(beneficioDto.id, beneficioDto)
          .then(resultUpdateBeneficio => {
            this.beneficioForm = resultUpdateBeneficio
          }).catch(error => {
            if (error.data && error.data.exception && error.data.exception.length > 0) {
              error.data.exception.forEach(e => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: e,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Error al modificar!',
                  },
                })
              })
            }
          })
          .finally(() => {
            this.isWorking = false
            this.overlayConfig.message = ''
          })
      } else {
        this.overlayConfig.message = 'Guardando nuevo beneficio'
        beneficiosResource().saveBeneficio(beneficioDto)
          .then(resultSaveBeneficio => {
            this.beneficioForm = resultSaveBeneficio
            this.backBeneficio()
          }).catch(error => {
            if (error.data && error.data.exception && error.data.exception.length > 0) {
              error.data.exception.forEach(e => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: e,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Error al guardar!',
                  },
                })
              })
            }
          })
          .finally(() => {
            this.isWorking = false
            this.overlayConfig.message = ''
          })
      }
    },
    validationBeneficioForm() {
      this.$refs.configBeneficioGenerico.validate().then(success => {
        if (success && this.validarArrayEmpty) {
          this.actionBeneficio()
        }
      })
    },
    backBeneficio() {
      this.$router.push('/beneficiolista')
    },
  },
}
</script>
