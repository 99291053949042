<template>
  <div>
    <b-form>
      <b-card>

        <b-overlay
          v-if="isWorking"
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div
              v-if="isWorking"
              class="mt-2"
            >
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
        </b-overlay>
        <b-row>

          <b-col
            md="10"
            class="m-0"
          >
            <h4>
              {{ title }}
            </h4>
          </b-col>
          <b-col md="2">
            <div
              style="float: right;"
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosUpd.includes(nameModulo) && showBtnEdit"
                id="btnEditConfigBeneficio"
                v-b-tooltip.hover
                variant="light"
                squared
                title="Editar Beneficio"
                data-toggle="button"
                aria-pressed="true"
                autocomplete="on"
                size="sm"
                :disabled="idBeneficio === undefined"
                @click="editBeneficio()"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <config-beneficio-generico
          v-if="beneficioDto.id || openMode === 'new'"
          :beneficio="beneficioDto"
          :editar-enable-disable="editar"
          :open-mode="openMode"
        />
        <!--
        <usuario-generico
          v-if="usuarioDto.id || openMode === 'new'"
          :usuario="usuarioDto"
          :editar-enable-disable="editar"
          :open-mode="openMode"
        />-->
      </b-card>
    </b-form>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { beneficiosResource } from '@/services/beneficiosResource'
import ConfigBeneficioGenerico from '@/views/beneficios/configuracion/ConfigBeneficioGenerico.vue'
// import UsuarioGenerico from './UsuarioGenerico.vue'

export default {
  components: {
    // UsuarioGenerico,
    ConfigBeneficioGenerico,
  },
  props: {
    idBeneficio: {
      type: String,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      isWorking: false,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: '',
      },
      nameModulo: 'CONFIGURACION_BENEFICIO',
      editar: false,
      beneficioDto: {
        id: null,
        nombre: '',
        codigo: '',
        beneficioTipo: {
          id: null,
          codigo: null,
          nombre: null,
          nombreCustom: null,
          descripcion: null,
        },
        esActivo: true,
        descripcion: null,
        controlarVigencia: false,
        vigenciaDesde: null,
        vigenciaHasta: null,
        controlarAntiguedad: false,
        fechaEntrega: null,
        controlarEdad: false,
        edadMaxima: null,
        controlarCantEntregas: false,
        cantEntregas: null,
        antiguedadMinima: null,
        solicitarAdjuntos: false,
        mostrarRequisitos: false,
        seleccionarProductos: false,
        delegacion: {
          id: null,
          nombre: null,
        },
        beneficioProducto: [],
      },
    }
  },
  computed: {
    showBtnEdit() {
      let show = false
      if (this.openMode === 'view') {
        show = true
      }
      return show
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    localStorage.setItem('lastPath', this.$route.path)
    this.findBeneficio(this.idBeneficio)
    this.setPageTitle()
  },
  methods: {
    editBeneficio() {
      if (this.editar) {
        this.editar = false
        this.title = 'Beneficio'
      } else {
        this.editar = true
        this.title = 'Edición de beneficio'
      }
    },
    setPageTitle() {
      if (this.openMode === 'edit') {
        this.title = 'Edición de beneficio'
      } else if (this.openMode === 'view') {
        this.title = 'Beneficio'
      } else {
        this.title = 'Nuevo beneficio'
      }
    },
    findBeneficio(idBeneficio) {
      this.isWorking = true
      this.overlayConfig.message = 'Recuperando datos de beneficio'
      if (idBeneficio !== undefined) {
        beneficiosResource().findBeneficioById(idBeneficio)
          .then(resultFindBeneficioById => {
            this.beneficioDto = { ...resultFindBeneficioById }
          }).catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.isWorking = false
          })
      } else {
        // this.title = 'Nuevo usuario'
        this.isWorking = false
      }
    },
  },
}
</script>
