import axios from 'axios'
import {
  postGetAllNotInThisList,

} from '@/utils/paths'

const pathPostGetAllNotInThisList = postGetAllNotInThisList

export function productoResource() {
  function findAllNotInThisList(arrayIds) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostGetAllNotInThisList, arrayIds)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    findAllNotInThisList,
  }
}
